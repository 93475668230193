import React, { useState, useEffect, useRef } from 'react';
import './styles/secondPage.css';
import AddressInput from './adress';
import LanguageSelector from './languageInput';
import './styles/adress.css';

const SecondPage = ({ onNext, onPrevious }) => {
  const [formData, setFormData] = useState({
    nimi: '',
    SyntymaAika: '',
    Sukupuoli: '',
    Sijainti: '',
    Paikka: '',
    VarausLinkki: '',
    Instagram: '',
    Facebook: '',
    locationData: null,
    initialSijainti: '', // Store initial Sijainti value
    Kielet: [], // Initialize Kielet as an empty array
    kokemus: '',
    hidedate: null
  });

  const handleKeyDown = (e, nextRef) => {
    if (e.key === 'Enter' && nextRef.current) {
        e.preventDefault(); // Prevent default form submission
        nextRef.current.focus(); // Move focus to the next input field
    }
};


  const nimiRef = useRef(null);
  const syntymaAikaRef = useRef(null);
  const sukupuoliRef = useRef(null);
  const kokemusRef = useRef(null);
  const sijaintiRef = useRef(null);
  const paikkaRef = useRef(null);
  const varausLinkkiRef = useRef(null);
  const instagramRef = useRef(null);
  const facebookRef = useRef(null);
  const palveluSukupuoliRef = useRef(null);
  

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const fetchData = async () => {
      try {
        const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setFormData({
            nimi: data.nimi || '',
            SyntymaAika: data.SyntymaAika ? new Date(data.SyntymaAika).toISOString().split('T')[0] : '',
            Sukupuoli: data.sukupuoli || '',
            Sijainti: data.kokoOsoiteRimpsu || '',
            Paikka: data.kampaamonNimi || '',
            VarausLinkki: data.VarausLinkki || '',
            locationData: data.kokoOsoiteRimpsu || null,
            initialSijainti: data.kokoOsoiteRimpsu || '', // Initialize initialSijainti
            Kielet: data.Kielet || [] ,
            PalveluSukupuoli: data.PalveluSukupuoli,// Initialize Kielet from fetched data
            Instagram: data.Instagram, 
            Facebook: data.Facebook,
            kokemus: data.kokemus,
            hidedate: data.hidedate// Set based on fetched data,
          });
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    
        if (name === 'kokemus') {
            // Only allow numbers in the kokemus field
            const numberValue = value.replace(/\D/g, ''); // Remove non-digit characters
            setFormData({ ...formData, [name]: numberValue });
        } else {
            // For all other inputs, update their values normally
            setFormData({ ...formData, [name]: value });
        }
    }


    const handleCheckboxChange = (e) => {
      const { name, checked } = e.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: checked }));
  };
  
  
  
  const handleLanguageChange = (languages) => {
    setFormData({ ...formData, Kielet: languages });
  };

  const handleNextClick = async () => {
    const token = localStorage.getItem("authToken");
    let formattedData = {
      ...formData,
      SyntymaAika: formData.SyntymaAika ? new Date(formData.SyntymaAika).toISOString() : '',
      hidedate: formData.hidedate // Include the checkbox state
    };
    

    // Check if the address has changed
    if (formData.Sijainti === formData.initialSijainti) {
      formattedData.Sijainti = '';
      formattedData.locationData = null;
    }

    try {
      const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formattedData)
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        onNext();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePreviousClick = async () => {
    const token = localStorage.getItem("authToken");
    let formattedData = {
      ...formData,
      SyntymaAika: formData.SyntymaAika ? new Date(formData.SyntymaAika).toISOString() : ''
    };

    // Check if the address has changed
    if (formData.Sijainti === formData.initialSijainti) {
      formattedData.Sijainti = '';
      formattedData.locationData = null;
    }

    try {
      const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formattedData)
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        onPrevious();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddressSelect = (locationData) => {
    setFormData({
      ...formData,
      Sijainti: locationData.formatted.split(',')[0],
      locationData
    });
  };

  return (
    <div className='pissingTHEBLUEAWAY'>
      <div className="container">
        <h1>Yleistä tietoa</h1>
        <h2>Nimesi</h2>
        <input 
          type="text" 
          name="nimi" 
          value={formData.nimi} 
          onChange={handleChange} 
          placeholder="Etunimi" 
          ref={nimiRef} 
          onKeyDown={(e) => handleKeyDown(e, syntymaAikaRef)} 
        />
        <h2>Syntymäpäiväsi</h2>
        <input 
          type="date" 
          name="SyntymaAika" 
          value={formData.SyntymaAika} 
          onChange={handleChange} 
          placeholder="SyntymäAika" 
          ref={syntymaAikaRef} 
          onKeyDown={(e) => handleKeyDown(e, sukupuoliRef)} 
        />
<div className="checkbox-container">
  <label>
    <input
      type="checkbox"
      name="hidedate"
      checked={formData.hidedate || false}
      onChange={handleCheckboxChange}
    />
    <span className="custom-checkbox"></span>
    <span className="small-text">Piilota syntymäaika profiilistasi</span>
  </label>
</div>

        <h2>Sukupuolesi</h2>
        <select 
          name="Sukupuoli" 
          value={formData.Sukupuoli} 
          onChange={handleChange} 
          ref={sukupuoliRef} 
          onKeyDown={(e) => handleKeyDown(e, kokemusRef)} 
        >
          <option value="Mies">Mies</option>
          <option value="Nainen">Nainen</option>
          <option value="Muu">Muu</option> 
        </select>

        <h2>Kauanko olet ollut alalla?</h2>
        <input
          type="text"
          name="kokemus"
          value={formData.kokemus} // Store only the numeric value
          onChange={handleChange}
          placeholder="Pelkkä vuosimäärä"
          onFocus={(e) => e.target.value = formData.kokemus} // Remove "vuotta" on focus
          onBlur={(e) => e.target.value = formData.kokemus ? `${formData.kokemus} vuotta` : ''} // Append "vuotta" back on blur
          ref={kokemusRef} 
          onKeyDown={(e) => handleKeyDown(e, sijaintiRef)} 
        />
        <h2>Toimipaikkasi osoite</h2>
        <AddressInput 
          value={formData.Sijainti} 
          onSelect={handleAddressSelect} 
          ref={sijaintiRef} 
          onKeyDown={(e) => handleKeyDown(e, paikkaRef)} 
        />
        <h2>Puhumasi kielet</h2>
        <LanguageSelector selectedLanguages={formData.Kielet} onLanguageChange={handleLanguageChange} />
        <h2>Oletko parturi vai kampaaja?</h2>
        <select 
          name="PalveluSukupuoli" 
          value={formData.PalveluSukupuoli} 
          onChange={handleChange} 
          ref={palveluSukupuoliRef} // Add ref for this select
          onKeyDown={(e) => handleKeyDown(e, paikkaRef)} 
        >
          <option value="Parturi">Parturi</option>
          <option value="Kampaaja">Kampaaja</option>
          <option value="Molemmat">Molemmat</option>
        </select>
        <h2>Toimipaikkasi nimi</h2>
        <input 
          type="text" 
          name="Paikka" 
          value={formData.Paikka} 
          onChange={handleChange} 
          placeholder="Paikka" 
          ref={paikkaRef} 
          onKeyDown={(e) => handleKeyDown(e, varausLinkkiRef)} 
        />
        <h2>Linkki käyttämääsi varaussivuun</h2>
        <input 
          type="text" 
          name="VarausLinkki" 
          value={formData.VarausLinkki} 
          onChange={handleChange} 
          placeholder="VarausLinkki" 
          ref={varausLinkkiRef} 
          onKeyDown={(e) => handleKeyDown(e, instagramRef)} 
        />
        <h2>Instagram-tunnuksesi</h2>
        <input 
          type="text" 
          name="Instagram" 
          value={formData.Instagram} 
          onChange={handleChange} 
          placeholder="Anna vain käyttäjätunnuksesi" 
          ref={instagramRef} 
          onKeyDown={(e) => handleKeyDown(e, facebookRef)} 
        />
        <h2>Facebook-tunnuksesi</h2>
        <input 
          type="text" 
          name="Facebook" 
          value={formData.Facebook} 
          onChange={handleChange} 
          placeholder="Anna vain käyttäjätunnuksesi" 
          ref={facebookRef} 
        />
        <div className="button-group69">
          <button onClick={handlePreviousClick}>Edellinen</button>
          <button onClick={handleNextClick}>Seuraava</button>
        </div>
      </div>
    </div>
  );}

  export default SecondPage;