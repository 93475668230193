import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './styles/thirdPage.css';

const initialItems = [
    { value: 'Sinettipidennys', inputs: ['', '', '', ''] },
    { value: 'Teippipidennys', inputs: ['', '', '', ''] },
    { value: 'Mago-pidennys', inputs: ['', '', '', ''] },
    { value: 'Ompelupidennys', inputs: ['', '', '', ''] },
];



const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

const Pidennykset = ({ onNext, onPrevious }) => {
  const [items, setItems] = useState(initialItems);
  const [deletedItems, setDeletedItems] = useState([]);
  const [bulkInputs, setBulkInputs] = useState(['', '', '', '']);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      getDataFromServer(token).then(data => {
        if (data && data.subPidennykset && data.subPidennykset.length > 0) {
          setItems(data.subPidennykset);
        }
      }).catch(error => {
        console.error("Failed to load data:", error);
      });
    }
  }, []);

  const handleInputChange = (index, inputIndex, value) => {
    const newItems = [...items];
    newItems[index].inputs[inputIndex] = value;
    setItems(newItems);
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setDeletedItems([...deletedItems, items[index]]);
    setItems(newItems);
  };

  const handleRestore = () => {
    const restoredItems = initialItems.filter(
      (initialItem) => !items.some((item) => item.value === initialItem.value)
    );
    setItems([...items, ...restoredItems]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = [...items];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setItems(newItems);
  };

  const handleNext = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      await postDataToServer(token, { subPidennykset: items });
      onNext();
    }
  };

  const handlePrevious = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      await postDataToServer(token, { subPidennykset: items });
      onPrevious();
    }
  };

  return (
    <div className="third-page-wrapper">
      <div className="third-page-container">
        <h1>Pidennykset</h1>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="Varjaykset">
            {(provided) => (
              <ul
                id="Varjaykset"
                className="sortable-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items.map((item, index) => (
                  <Draggable key={item.value} draggableId={item.value} index={index}>
                    {(provided) => (
                      <li
                        className="ui-state-default"
                        data-value={item.value}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span className="number">{index + 1}.</span>
                        <span className="ui-icon ui-icon-arrowthick-2-n-s"></span>
                        {item.value}
                        <button className="delete-button" onClick={() => handleDelete(index)}>
  Poista
</button>
<div className="input-container">
  <label htmlFor={`price-input-${index}`}>Hinta alkaen:</label>
  <input
  id={`price-input-${index}`}
  type="text"
  value={item.inputs[0] !== '' ? `${item.inputs[0]}€` : ''} // Show the value with € when not empty
  inputMode="numeric"
  pattern="\d*"
  placeholder="0"
  onChange={(e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    if (value || value === '') {
      handleInputChange(index, 0, value); // Update only the first input of each item
    }
  }}
  onBlur={(e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Clean value on losing focus
    handleInputChange(index, 0, value); // Update the value without extra characters
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === 'ArrowDown') {
      const nextInput = document.getElementById(`price-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus(); // Focus the next input field
      }
    }
    if (e.key === 'ArrowUp') {
      const previousInput = document.getElementById(`price-input-${index -1}`);
      if (previousInput) {
        previousInput.focus(); // Focus the next input field
      }
    }
  }}
/>
</div>

                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="button-group69">
          <button id="Tuo takaisin" onClick={handleRestore}>
            Tuo takaisin
          </button>
        </div>
        <div className="button-group69">
          <button onClick={() => { handlePrevious(); handlePrevious(); }}>Edellinen</button>
          <button onClick={() => { handleNext(); handleNext(); }}>Seuraava</button>
        </div>
      </div>
    </div>
  );  
};
export default Pidennykset;
